import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { LibraryContext } from '../../contexts/library-context';

function Menu({ title, active, style, onClick, children }) {
  const [loaded, setLoaded] = useState(false);
  const { setContentComponent } = useContext(LibraryContext);

  useEffect(() => {
    if (active && !loaded && children) {
      setLoaded(true);
      // console.log(`LOAD CHILDREN - ${title}`);
      React.Children.map(children, child => setContentComponent({type: child.type, params: child.props}));
    } else if (!active && loaded && children) {
      setLoaded(false);
    }
  }, [active, loaded, children])

  return (
    <div onClick={onClick} style={{ color: active ? `white` : `#4f4e50`, margin: `10px 15px 10px 15px`, fontSize: 11, fontWeight: `bold`, cursor: `pointer`, ...style}}>
      {title}
    </div>
  )
}

Menu.propTypes = {
  title: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  style: PropTypes.object,
  onClick: PropTypes.func,
}

export default Menu;
