import { useState, useEffect, useContext } from "react";

// Don't forget to Provide the API to the context to the ROOT
import { LibraryContext } from '../contexts/library-context';

const useGetFromApi = (path) => {
  const [loaded, setLoaded] = useState(false);
  const [items, setItems] = useState(null);
  const { api } = useContext(LibraryContext);

  useEffect(() => {
    if (api && !loaded && path) {
      setLoaded(true);
      api.getFromApi(path)
        .then( (response) => setItems(response.data.results) )
        .catch( e => console.log(`error`, e.response.status, e.response.statusText) );
    }
  }, [api, loaded, path])

  return items;
};

export default useGetFromApi;
