import React from 'react';
// import PropTypes from 'prop-types';

function Mixer(props) {
  const { style } = props;

  return (
    <div style={style}>
      <div id="arrow1" className="arrow arrow1" style={{position: `absolute`, top: 245, left: 45}} onMouseDown={() => window.mixer_fadeto(1)}>◄</div>
      <div id="arrow2" className="arrow arrow2" style={{position: `absolute`, top: 245, left: 266}} onMouseDown={() => window.mixer_fadeto(2)}>►</div>

      <div className="button" id="headphone1" onMouseDown={() => window.headphone_button_toggle(1)} style={{display: `none`, position: `absolute`, top: 231, left: 8, width: 32, borderRadius: 2}}>
        <div style={{position: `absolute`, top: 8, left: 6}} className="headphone" />
      </div>
      <div className="button" id="headphone2" onMouseDown={() => window.headphone_button_toggle(2)} style={{display: `none`, position: `absolute`, top: 231, left: 290, width: 32, borderRadius: 2}}>
        <div style={{position: `absolute`, top: 8, left: 6}} className="headphone" />
      </div>

      <div id="bpm_button" className="button2" style={{position: `absolute`, height: 29, width: 102, top: -2, left: 115}}>
        <div id="bpm_value1" style={{display: `none`, width: 102, textAlign: `center`, position: `absolute`, left: 0, top: 0, fontSize: 20}}>...<font size="2"> bpm</font></div>
        <div id="bpm_value2" style={{display: `none`, width: 80, textAlign: `right`, position: `absolute`, left: 2, top: 0, fontSize: 20}}><span style={{letterSpacing: 2}}>---</span><font size="2"> bpm</font></div>
        <div id="bpm_value3" style={{display: `none`, width: 80, textAlign: `right`, position: `absolute`, left: 2, top: 3, fontSize: 20}}><img alt="bpm" style={{position: `absolute`, left: 29, top: 7, opacity: 0.65}} src="picx/bpm_loading.gif" height="14" /><font size="2">bpm</font></div>
        <div id="bpm_value4" style={{display: `none`, width: 120, textAlign: `center`, position: `absolute`, left: -9, top: 1, fontSize: 18}} />
        <div id="bpm_value5" style={{display: `none`, width: 120, textAlign: `center`, position: `absolute`, left: -9, top: 1, fontSize: 18, color: `#F70`}}>automix</div>
        <div style={{position: `absolute`, width:102, height:6, top:32, left:0, background: `rgba(255, 255, 255, 0.1)`}} />
        <div id="beatvisu1" style={{position: `absolute`, width:102, height:3, top:32, left:0, background: `#FF6600`, clip: `rect(0px,0px,0px,0px)`}} />
        <div id="beatvisu2" style={{position: `absolute`, width:102, height:3, top:35, left:0, background: `#D70046`, clip: `rect(0px,0px,0px,0px)`}} />
        <div style={{position: `absolute`, top:32, left:0}} className="beatvisu" />
      </div>

      <table id="bpm_menu" className="bpm-menu origin" style={{display: `none`, top:50, left:12}} width="310" height="223" bgcolor="#222224" border="0">
        <tbody>
          <tr>
            <td valign="top" align="center" style={{background: `#000`}} width="310" height="40">
              <div id="bpm_state1" style={{display: `none`, position: `relative`, left:-4, top:4, width:300}}>
                <div style={{display: `inline-block`, position: `relative`, left:0, top:5, opacity:0.8}} className="bpm_state2"></div> beats in sync <div id="bpm_blink" style={{display: `inline-block`, position: `relative`, left:2, top:1, height:12, width:12, borderRadius: `50`, background: `#F90`}}></div>
              </div>
              <div id="bpm_state2" style={{display: `none`, position: `relative`, left:-4, top:4, width:300}}>
                <div style={{display: `inline-block`, position: `relative`, left:0, top:7, opacity:0.5}} className="bpm_state1"></div> beats not in sync <a className="af" href="#infos" onMouseDown={() => window.showToolTips(this,'nos')}>(more info)</a>
              </div>
              <div id="bpm_state3" style={{display: `none`, position: `relative`, left:0, top:12, width:300}}><img alt="bpm" style={{position: `relative`, left:-1, top:3, opacity:0.65}} src="picx/bpm_loading.gif" height="14" /> detecting bpm... </div>
              <div id="bpm_state4" style={{display: `none`, position: `relative`, left:0, top:12, width:300}}>manual tempo and sync mode</div>
              <div id="bpm_state5" style={{display: `none`, position: `relative`, left:0, top:12, width:300}} />
              <div onMouseDown={() => window.bpm_menu(false)} className="bpm_menu_close" style={{position: `absolute`, right:0, top:0, width:30, height:30}}>
                <svg style={{position: `absolute`, left:9, top:7}} width="15" height="15">
                  <path fill="none" strokeWidth="2.5" d="M1,1 L14,14 M14,1 L1,14" />
                </svg>
              </div>
            </td>
          </tr>
          <tr id="automix_panel" style={{position: `relative`, display: `done`}}>
            <td align='center' style={{paddingTop: 20}}>
              <div className="center" style={{position: `absolute`, top:60}}>The mixer is currently automatically mixing :</div>
              <div id="automix_info" className="center" style={{position: `absolute`, top:87, color: `#F70`}} />
              <div className="center" style={{position: `absolute`, top:126}}>
                <a className='link_botton_orange' href="#stop" onMouseUp={() => window.automix_cancel()}>STOP AUTOMIX</a>&nbsp;&nbsp;<a className='link_botton_grey' href="#automix" onMouseUp={() => window.automix_nextnow()}>next song ></a>
              </div>
            </td>
          </tr>
          <tr id="bpm_panel" style={{position: `relative`, display: `none`}}>
            <td valign="top" height="104">
              <div id="bpm_coef_info" className="center" style={{position: `absolute`, top:60, color: `#F70`}}>...</div>
              <div id="bpm_coef" style={{position: `absolute`, top:84, left:10, color: `#999`, background: `#333335`, width:290, height:26, cursor: `pointer`}}>
                <div style={{position: `absolute`, top:12, left:9, background: `#999`, width:8.5, height:1.5}} />
                <div style={{position: `absolute`, top:4, left:272, color: `#999`, fontSize: 20}}>+</div>
                <div style={{position: `absolute`, top:0, left:145, background: `#999`, width:1, height:26}} />
                <div id="bpm_coef_cursor" className="bpm_cursor" style={{position: `absolute`, top:0, left:132}}>
                  <div style={{position: `absolute`, top:0, left:12, background: `#FFF`, width:2, height:26}} />
                </div>
              </div>
              <div id="bpm_info" className="center" style={{position: `absolute`, top:143, color: `#999`}}>...</div>
              <div className="center" style={{position: `absolute`, top:187}}>
                <input onClick={() => window.settings_cb(this)} type="checkbox" id="b06b" className="css-checkbox" />
                <label htmlFor="b06b" className="css-label" style={{fontSize:14}}>disable auto tempo and beatsync</label>
                <span className="info1" onMouseDown={() => window.showToolTips(this,'b06')} />
                <div id="bpm_dotrui_ghion" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div id="eq1all">
        <div id="eq11" style={{position: `absolute`, left:42, top:60}} className="eq eqa pointer">
          <span style={{position: `absolute`, display: `block`, left:10, top:8}} className="eqc" />
        </div>
        <div style={{position: `absolute`, left:42, top:94}} className="eqd">mid</div>
        <div id="eq12" style={{position: `absolute`, left:42, top:113}} className="eq eqa pointer">
          <span style={{position: `absolute`, display: `block`, left:10, top:8}} className="eqc" />
        </div>
        <div style={{position: `absolute`, left:42, top:147}} className="eqd">bass</div>
        <div id="eq13" style={{position: `absolute`, left:42, top:166}} className="eq eqa pointer">
          <span style={{position: `absolute`, display: `block`, left:10, top:8}} className="eqc" />
        </div>
        <div style={{position: `absolute`, left:42, top:200}} className="eqd">filter</div>
        <div id="eq1full" style={{display: `none`}}>
          <div id="eq14" style={{position: `absolute`, left:-8, top:60}} className="eq eqa pointer">
            <span style={{position: `absolute`, display: `block`, left:10, top:8}} className="eqc" />
          </div>
          <div style={{position: `absolute`, left:-8, top:94}} className="eqd">high</div>
          <div id="eq15" style={{position: `absolute`, left:-8, top:113}} className="eq eqa pointer">
            <span style={{position: `absolute`, display: `block`, left:10, top:8}} className="eqc" />
          </div>
          <div style={{position: `absolute`, left:-8, top:147}} className="eqd">gain</div>
        </div>
      </div>

      <div id="eq2all">
        <div id="eq21" style={{position: `absolute`, left:246, top:60}} className="eq eqb pointer">
          <span style={{position: `absolute`, display: `block`, left:10, top:8}} className="eqc" />
        </div>
        <div style={{position: `absolute`, left:246, top:94}} className="eqd">mid</div>
        <div id="eq22" style={{position: `absolute`, left:246, top:113}} className="eq eqb pointer">
          <span style={{position: `absolute`, display: `block`, left:10, top:8}} className="eqc" />
        </div>
        <div style={{position: `absolute`, left:246, top:147}} className="eqd">bass</div>
        <div id="eq23" style={{position: `absolute`, left:246, top:166}} className="eq eqb pointer">
          <span style={{position: `absolute`, display: `block`, left:10, top:8}} className="eqc" />
        </div>
        <div style={{position: `absolute`, left:246, top:200}} className="eqd">filter</div>
        <div id="eq2full" style={{display: `none`}}>
          <div id="eq24" style={{position: `absolute`, left:296, top:60}} className="eq eqb pointer">
            <span style={{position: `absolute`, display: `block`, left:10, top:8}} className="eqc" />
          </div>
          <div style={{position: `absolute`, left:296, top:94}} className="eqd">high</div>
          <div id="eq25" style={{position: `absolute`, left:296, top:113}} className="eq eqb pointer">
            <span style={{position: `absolute`, display: `block`, left:10, top:8}} className="eqc" />
          </div>
          <div style={{position: `absolute`, left:296, top:147}} className="eqd">gain</div>
        </div>
      </div>

      <div id="fader1" className="pointer" style={{position: `absolute`, width:50, top:68, left:123}}>
        <div className="fader_bg" style={{position: `absolute`, top:-14, left:-7}} />
        <div id="vumeter1" className="fader_vumeter1" style={{position: `absolute`, top:0, left:0, clip: `rect(138px,21px,141px,0px)`}} />
        <div id="fader1_cursor" className="fader_cursor" style={{position: `absolute`, top:0, left:-12, transform: `translate3d(0px, -4px, 0px)`}} />
      </div>

      <div id="fader2" className="pointer" style={{position: `absolute`, width:50, top:68, left:188}}>
        <div className="fader_bg" style={{position: `absolute`, top:-14, left:-7}} />
        <div id="vumeter2" className="fader_vumeter2" style={{position: `absolute`, top:0, left:0, clip: `rect(138px,21px,141px,0px)`}} />
        <div id="fader2_cursor" className="fader_cursor" style={{position: `absolute`, top:0, left:-12, transform: `translate3d(0px, -4px, 0px)`}} />
      </div>

      <div id="crossfader" className="pointer crossfader_bg" style={{position: `absolute`, top:235, left:68}}>
        <div id="crossfader_cursor" className="crossfader_cursor" style={{position: `absolute`, top:-6, left:98, transform: `translate3d(-11px, 0px, 0px)`}} />
      </div>
    </div>
  )
}

// Mixer.propTypes = {
//
// }

export default Mixer;
