import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList, areEqual } from "react-window";

import Spinner from './Spinner';

import DragItem from '../DragItem';
import MidDropBox from '../MidDropBox';

import {SortableContext} from '../../../contexts/sortable-context';
import {LibraryContext} from '../../../contexts/library-context';

import useApiOrLocal from '../../../hooks/useApiOrLocal';

function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  if (startIndex <= endIndex) {
    result.splice((endIndex < 0) ? 0 : endIndex, 0, removed);
  } else {
    result.splice(endIndex + 1, 0, removed);
  }
  return result;
}

// Recommended react-window performance optimisation: memoize the row render function
const Row = React.memo(function Row(props) {
  const { data: items, index, style } = props;
  const item = items[index];

  const { isSortable, idParent, setParent, setItems } = useContext(SortableContext)
  const { selectedItems, setSelectedItems, api } = useContext(LibraryContext);

  const onReorder = (source, endIndex, setParent = null) => (item, monitor) => {

    let newItems = [];
    if (source === item.idParent) { //SAME PARENT

      if (selectedItems.length === 1) {
        const startIndex = items.findIndex(x => x.id === item.id);
        newItems = reorder(items.map(x => { x.selected = false; return x }), startIndex, endIndex); // JUST ONE ELEMENT
      } else {
        let itemReference = items.find((x,i) => i === endIndex + 1);
        const idsAvailable = selectedItems.map( x => x.id );
        newItems = items.reduce( (b, x) => {
          if (!idsAvailable.includes(x.id)) { b.push(x) }
          return b;
        } ,[]);
        const startIndex = (itemReference) ? newItems.findIndex(x => x.id === itemReference.id) : newItems.length;
        newItems.splice(startIndex, 0, ...selectedItems.map(x => { x.selected = false; return x }));
      }

    } else { // DIFFERENT PARENT
      newItems = [...items];
      newItems.splice(endIndex + 1, 0, ...selectedItems.map(x => { x.selected = false; return x })); //newItems.splice(endIndex + 1, 0, item);
    }


    setItems(newItems);
    setParent(newItems); //TODO - Parent for what again ?
  }

  const deleteItem = () => {
    items.splice(index, 1);
    setItems(items.map(x => {x.selected = false; return x;}));
    setParent(items.map(x => {x.selected = false; return x;}));
  }

  const select = (e) => {
    if (e.altKey || e.metaKey) {
      item.selected = !item.selected;
    } else if (e.shiftKey) {
      const lastIDSelected = items.filter(x => x.selected).pop().id;
      const lastIndexSelected = items.findIndex( x => x.id === lastIDSelected);
      if (lastIndexSelected <= index) {
        items.forEach( (x,i) => { if ( lastIndexSelected <= i && i <= index) x.selected = true } )
      } else {
        items.forEach( (x,i) => { if (i <= lastIndexSelected && index <= i) x.selected = true } )
      }
    } else {
      items.forEach( (x,i) => x.selected = (index === i) );
    }

    setItems([...items]);
  }
  const begin = () => {
    if (!item.selected) {
      item.selected = !item.selected;
      setItems([...items]);
    }
    setSelectedItems(items.filter( x => x.selected ));
  };

  const end = (item, monitor) => { // Only Drop Exterior or Escape
    setSelectedItems([]);
    if (!monitor.getDropResult()) {
      setItems([...items.map(x => {x.selected = false; return x})]);
    }
  }

  const playerLoadSong = (_pid, audio, _autoplay) => {
    // YOU.DJ
    // window.context_unlock();
    // window[`player${_pid}`].loadsong(audio, _autoplay);

    api.getFromApi(`/catalog/tracks/${audio.id}/download/`)
      .then( (response) => {
        console.log('OH YEAH - GET AUDIO FROM API BEATPORT')
        // window.context_unlock();
        // window[`player${_pid}`].loadsong({
        //   type: 1,
        //   a: audio.artists,
        //   bpm: audio.bpm,
        //   d1: audio.length_s,
        //   d2: audio.lenght,
        //   t: audio.title,
        //   i: audio.img,
        //   url: response.data.location
        // }, true);

        window['youdj'].yoann(_pid, response.data.location, audio.title, audio.length_s, audio.id, audio.img, true);

      } )
      .catch( e => console.log(`error`, e.response.status, e.response.statusText) );
  }

  const styleMidDropBox = {
    backgroundColor : `transparent`, zIndex: 20, position: `absolute`,
    width: style.width, height: style.height / 2
  }

  const GUTTER_SIZE = 2;
  const mergeStyle = (index, style) => ({
    ...style,
    top: style.top + GUTTER_SIZE,
    height: style.height - GUTTER_SIZE,
    backgroundColor: (index % 2 === 0) ? `black` : `#1d1d1f`,
    textAlign: `center`
  });

  const limitString = (text, count) => text.slice(0, count) + (text.length > count ? "..." : ``);

  return (
    <DragItem type={`TRACK`} idParent={idParent} begin={begin} end={end} onClick={select} item={item} style={mergeStyle(index, style)}>
      <div style={{width: 45, fontSize: 15, fontWeight: `bold`}}>{index + 1}</div>
      <div style={{width: 40, height: 40}}><img alt="" src={item.img} width={40} height={40} /></div>
      <div style={{width: `18%`}}>{item.title}</div>
      <div style={{width: `10%`, color: `#8a8a90`}}>{limitString(item.artists, 35)}</div>
      <div style={{width: `10%`, color: `#8a8a90`}}>{item.label}</div>
      <div style={{width: `10%`, color: `#8a8a90`}}>{item.genre}</div>
      <div style={{width: `5%`, color: `#8a8a90`}}>{item.bpm}</div>
      <div style={{width: `5%`, color: `#8a8a90`}}>{item.key}</div>
      <div style={{width: `5%`, color: `#8a8a90`}}>{item.length}</div>
      <div style={{width: `8%`, color: `#8a8a90`}}>{item.release}</div>
      <div onClick={() => playerLoadSong(1, item, true)} style={{width: 30, zIndex:40}}><i className="fa fa-play-circle" style={{color: `white`, fontSize: 20, transform: `rotate(180deg)`}} aria-hidden="true"/></div>
      <div onClick={() => playerLoadSong(2, item, true)} style={{width: 30, zIndex:40}}><i className="fa fa-play-circle" style={{color: `white`, fontSize: 20}} aria-hidden="true"/></div>

      { isSortable && <div style={{width: `5%`, zIndex: 50}} onClick={deleteItem}>x</div> }

      { isSortable && (
        <>
          <MidDropBox accept={`TRACK`} drop={onReorder(idParent, index - 1, setParent)} style={{ ...styleMidDropBox, top: 0, borderTop: `1px solid red` }} />
          <MidDropBox accept={`TRACK`} drop={onReorder(idParent, index, setParent)} style={{ ...styleMidDropBox, bottom: 0, borderBottom: `1px solid red` }} />
        </>
      ) }

    </DragItem>
  )
}, areEqual);

function List({ pathApi = null, data = [], title = null, width = `120%`, height = 400, size = 42, sortable, idParent, setParent, style }) {
  const { items, setItems } = useApiOrLocal(pathApi, data, idParent);

  return (
    <div style={{...style}}>
      { title && <span style={{ fontSize: `1.5em`, fontWeight: `bold`, color: `white`}}>{title}</span> }
      { (!items)
          ? <Spinner />
          : (
            <SortableContext.Provider value={{ isSortable: sortable, idParent, setParent, setItems }}>
              <FixedSizeList
                height={height + ((title) ? 15 : 0)}
                itemCount={items.length}
                itemSize={size}
                width={width}
                itemData={items}
                style={{marginTop: (title) ? 15 : 0}}
              >
                {Row}
              </FixedSizeList>
            </SortableContext.Provider>
          )
      }
    </div>
  )

}

List.propTypes = {
  pathApi: PropTypes.string,
  items: PropTypes.array,
  width: PropTypes.any,
  height: PropTypes.any,
  size: PropTypes.number,
  sortable: PropTypes.bool.isRequired,
  setParent: PropTypes.func,
  style: PropTypes.object,
}

export default List;
