import React from 'react';
import PropTypes from 'prop-types';
import './Spinner.css';

function Spinner({ style }) {
  return (
    <div className="spinner" style={style}>
      <div className="rect1"></div>
      <div className="rect2"></div>
      <div className="rect3"></div>
      <div className="rect4"></div>
      <div className="rect5"></div>
    </div>
  )
}

Spinner.propTypes = {
  style: PropTypes.object
}

export default Spinner;
