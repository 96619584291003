import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import { useDragLayer, DragLayer } from 'react-dnd';
import '../Library.css';

import {LibraryContext} from '../../contexts/library-context';

// const layerStyles = {
//   position: 'fixed',
//   pointerEvents: 'none',
//   zIndex: 100,
//   left: 0,
//   top: 0,
//   width: '100%',
//   height: '100%'
// }
//
// function getItemStyles(props) {
//   const { currentOffset } = props
//   if (!currentOffset) {
//     return {
//       display: 'none'
//     }
//   }
//
//   const { x, y } = currentOffset
//   const transform = `translate(${x}px, ${y}px)`
//   return {
//     transform: transform,
//     WebkitTransform: transform
//   }
// }
//
// function CustomDragLayer({ item, itemType, isDragging }) {
//   if (!isDragging) {
//     return null
//   }
//
//   function renderItem(type, item) {
//     return <div>coucou</div>
//   }
//
//   return (
//     <div style={layerStyles}>
//       <div style={getItemStyles(props)}>{renderItem(itemType, item)}</div>
//     </div>
//   )
// }
//
// function collect(monitor) {
//   return {
//     item: monitor.getItem(),
//     itemType: monitor.getItemType(),
//     currentOffset: monitor.getSourceClientOffset(),
//     isDragging: monitor.isDragging()
//   }
// }
//
// export default DragLayer(collect)(CustomDragLayer)

function CustomDragLayer({ onDragActive = () => {}, onDragInactive = () => {} }) {
  const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%', //200
    height: '100%', //40
  }

  const { selectedItems } = useContext(LibraryContext);

  const getItemStyles = (initialOffset, currentOffset, clientOffset) => {
    if (!initialOffset || !currentOffset || !clientOffset ) {
      return {
        display: 'none',
      }
    }
    let { x, y } = clientOffset; //clientOffset; //currentOffset
    x += 160; //40;
    // y += 40; //10;
    // console.log(currentOffset, clientOffset)

    const transform = `translate(${x}px, ${y}px)`
    return {
      cursor: 'copy',
      background: 'green',
      transform,
      WebkitTransform: transform,
    }
  }

  const {
    isDragging,
    initialOffset,
    currentOffset,
    clientOffset
  } = useDragLayer((monitor) => ({
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    clientOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  }))
  if (!isDragging) onDragInactive();
  if (isDragging) onDragActive();
  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset, clientOffset)}>
        <span style={{display: "inline-block"}} className={`tag`}>{selectedItems.length}</span>
      </div>
    </div>
  )
}

// <div style={getItemStyles(initialOffset, currentOffset, clientOffset)}>
//   <span style={{display: "inline-block"}} className={`tag`}>{selectedItems.length}</span>
// </div>

CustomDragLayer.propTypes = {
  onDragActive: PropTypes.func,
  onDragInactive: PropTypes.func
}

export default CustomDragLayer;
