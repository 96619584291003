import React from 'react';
import PropTypes from 'prop-types';

function TopManager({ title, style, children }) {
  return (
    <div style={{...style}}>
      { React.Children.map(children, child => (React.isValidElement(child)) && React.cloneElement(child)) }
    </div>
  )
}

TopManager.propTypes = {
  style: PropTypes.object
}

export default TopManager;
