import React, { /*useState*/ } from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';

window.timers = []; // More reliable that Hooks.

function Playlist({ name, style, drop = () => {}, onClick }) {

  // const [timer, setTimer] = useState(null)
  const [{ canDrop, isOver }, refDrop] = useDrop({
    accept: 'TRACK',
    drop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    }),
  })
  const background = (canDrop && isOver) ? `#5a5757` : `#1f1e1f`;

  // Open like Spotify like
  if (canDrop && isOver && !window.timers[name]) {
    console.log('init timer', name);
    window.timers[name] = setTimeout(() => {
      console.log(`Switch playlist ${name}`);
       onClick()
    }, 1000);
  } else if ( canDrop && !isOver && window.timers[name] ) {
    console.log('CLEAN AND STOP');
    clearTimeout(window.timers[name]);
    window.timers[name] = null;
  }

// let [timer, setTimer] = useState(null); //WORKS BUT WITH HOOK TIMER NOT KILL ON TIME
  // if (canDrop && isOver && !timer) {
  //   setTimer(setTimeout(() => {
  //     console.log(`Switch playlist ${name}`);
  //      onClick()
  //   }, 1000));
  // } else if ( canDrop && !isOver && timer ) {
  //   console.log('CLEAN AND STOP');
  //   clearTimeout(timer);
  //   setTimer(null);
  // }


  //TODO - UPGRADE
  // document.body.style.cursor = (canDrop && isOver) ? `copy` : "default";

  return (
    <div ref={refDrop} onClick={onClick} style={{...style, background}}>
      <span>{name}</span>
    </div>
  )
}

Playlist.propTypes = {
  name: PropTypes.string.isRequired,
  style: PropTypes.object,
  drop: PropTypes.func,
  onClick: PropTypes.func,
}

export default Playlist;
