import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import '../Library.css';

import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

function DragItem({ type, item, style, idParent, begin, end, onClick, children }) {

  const [{ isDragging }, dragRef, preview] = useDrag({
    item: { ...item, type, idParent },
    begin: begin,
    end: end,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })
  const background = (item.selected) ? `#5a5757` : style.backgroundColor; // (isDragging || item.selected)

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])

  return (
      <div ref={dragRef} onClick={onClick} style={{...style, background}} className={`item`} >
        { React.Children.map(children, (child, index) => {
            if (React.isValidElement(child)) {
                return React.cloneElement(child, {})
            }
          })
        }
      </div>
  );
}

DragItem.propTypes = {
  type: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  style: PropTypes.object,
  onClick: PropTypes.func,
  begin: PropTypes.func.isRequired,
  end: PropTypes.func.isRequired,
}

export default DragItem;
