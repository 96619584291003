import React from 'react';
import PropTypes from 'prop-types';

function Content({ name, style, children }) {

  return (
    <div style={{marginBottom: 20, ...style}}>
      <span style={{ fontSize: `1.5em`, fontWeight: `bold` , color: `white`}}>{name}</span>
      { React.Children.map(children, child => (React.isValidElement(child)) && React.cloneElement(child)) }
    </div>
  )
}

Content.propTypes = {
  name: PropTypes.string.isRequired,
  style: PropTypes.object
}

export default Content;
