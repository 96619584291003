import React from 'react';
import PropTypes from 'prop-types';
import './Search.css';

function Search({ style, value, placeholder, onChange }) {

  return (
    <div className="search-box" style={style}>
      <div className="search-icon"><i className="fa fa-search" /></div>
      <input className="search-input" value={value} onChange={onChange} type="text" placeholder={placeholder} />
    </div>
  )
}

Search.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func
}

export default Search;
