import React from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';

import '../Library.css';

function CreatePlaylist({ style, drop, onClick }) {

  const [{ canDrop, isOver }, refDrop] = useDrop({
    accept: ['TRACK', 'PLAYLIST'],
    drop: drop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })
  const background = (canDrop && isOver) ? `#5a5757` : `#1f1e1f`;

  //TODO - UPGRADE
  // document.body.style.cursor = (canDrop && isOver) ? `copy` : "default";

  return (
    <div ref={refDrop} className={`eaction`} onClick={() => onClick()} style={{...style, background, cursor: `pointer`}}>
      Create a playlist
    </div>
  )
}

CreatePlaylist.propTypes = {
  style: PropTypes.object,
  drop: PropTypes.func.isRequired,
  onClick: PropTypes.func
}

export default CreatePlaylist;
