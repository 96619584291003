import * as Config from '../config';
import { useState, useEffect, useContext } from "react";

// Don't forget to Provide the API to the context to the ROOT
import { LibraryContext } from '../contexts/library-context';

const axios = require('axios');

const useApiOrLocal = (path = null, data = [], idParent) => {
  const [loaded, setLoaded] = useState(false);
  const [items, setItems] = useState(null);
  const { api } = useContext(LibraryContext);

  const formatted = x => ({ ...x, ref: `${x.id}`, selected: false});
  const formattedFromYD = x => ({
    ...x,
    title: x.t,
    label: `Record Label`,
    img: x.i,
    genre: x.g,
    artists: x.a,
    key: `KEY`,
    release: `10/10/20`,
    length: x.d2,
    length_s: x.d1,
    ref: `${x.id}`, selected: false
  });
  const formattedFromBP = x => ({
    ...x,
    title: x.name,
    label: x.release.label.name,
    img: (x.release.image) ? x.release.image.uri : Config.DEFAULT_IMG,
    genre: x.genre.name,
    artists: x.artists.map(artist => artist.name).join(', '),
    key: x.key.name,
    release: x.new_release_date,
    length_s: x.length_ms / 1000,
    ref: `${x.id}`, selected: false
  })

  useEffect(() => {
    if (api && !loaded) {
      setLoaded(true);
      if (path) {
        if (path.includes('you.dj')) {
          axios.get(`${path}`)
          .then( response => setItems(response.data.map(formattedFromYD)) )
          .catch( e => console.log(`error`, e.response.status, e.response.statusText) );
        } else {
          setLoaded(false);
          api.getFromApi(path)
            .then( (response) => setItems(response.data.results.map(formattedFromBP)) )
            .catch( e => console.log(`error getFromApi`, e) );
        }
      } else {
        setLoaded(false);
        setItems(data.map(formatted));
      }
    }

  }, [api, loaded, idParent])

  return { items, setItems };
};

export default useApiOrLocal;
