import React from 'react';
import PropTypes from 'prop-types';

import Spinner from './Spinner';
import Card from './Card';

import useGetFromApi from '../../../hooks/useGetFromApi';

// TODO - Virtualized GRID

function GridTracks({ label, pathApi, style }) {
  const items = useGetFromApi(pathApi);

  const format = x => ({
    id: x.id,
    title: x.name,
    artists: x.artists.map(artist => artist.name).join(', '),
    img: x.release.image.uri,
    label: x.release.label.name,
    genre: x.genre.name,
    key: x.key.name,
    release: x.new_release_date,
    length_s: x.length_ms / 1000
  });

  return (
    <div style={{...style}}>
      { label && <h4 style={{color: `white`}}>{label}</h4> }
      { (!items)
          ? <Spinner />
          : (
            <div style={{ display: `flex`, flexDirection: `row`, flexWrap: `wrap`, alignContent: `flex-start`, justifyContent: `flex-start` }}>
              { items.map(format).map( (item, i) =>
                <Card key={i}
                  dragEnabled={true}
                  title={item.title}
                  artists={item.artists}
                  img={item.img}
                  audio={item}
                  style={{width: 150, marginRight: 15, marginBottom: 20, position: `relative`}}
                />
              ) }
            </div>
          )
      }
    </div>
  )
}

GridTracks.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  pathApi: PropTypes.string.isRequired,
  style: PropTypes.object
}

export default GridTracks;
