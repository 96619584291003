import React from 'react';
import PropTypes from 'prop-types';

export function Separator() {
  return <hr/>
}

function LeftManager({ title, style, styleTitle, children }) {
  return (
    <div style={{...style}}>
      <h2 style={{...styleTitle}}>{title}</h2>
      { React.Children.map(children, child => (React.isValidElement(child)) && React.cloneElement(child)) }
    </div>
  )
}

LeftManager.propTypes = {
  title: PropTypes.string.isRequired,
  styleTitle: PropTypes.object,
  style: PropTypes.object
}

export default LeftManager;
