import * as Config from '../config';
import { useState, useEffect } from "react";
const axios = require('axios');
const querystring = require('querystring');

const useApi = () => {
  const [api, setApi] = useState(null);
  useEffect(() => {
    // console.log(`http -f https://api.beatport.com/v4/auth/o/token/ client_id=${CLIENT_ID} client_secret=${CLIENT_SECRET} grant_type=client_credentials`);
    axios
      .post(`${Config.API_URL}/auth/o/token/`, querystring.stringify({
        client_id: Config.API_CLIENT_ID,
        client_secret: Config.API_CLIENT_SECRET,
        // grant_type: `client_credentials`
        username: Config.API_USERNAME,
        password: Config.API_PASSWORD,
        grant_type: `password`
      }), { headers: { 'content-type': 'application/x-www-form-urlencoded; charset=utf-8' } })
      .then( response => {
        const token = response.data;
        const getFromApi = path => {
          return axios.get(`${Config.API_URL}${path}`, { headers: { 'Authorization': `Bearer ${token.access_token}` }});
        }
        setApi({ token, getFromApi })
      })
      .catch( e => console.log(`error`, e.response.status, e.response.statusText) );

  }, []);

  return api;
};

export default useApi;
