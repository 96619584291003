import * as Config from '../../../config';
import React from 'react';
import PropTypes from 'prop-types';

import Spinner from './Spinner';
import Card from './Card';

import useGetFromApi from '../../../hooks/useGetFromApi';

// TODO - Virtualized GRID

function GridCharts({ pathApi, style }) {
  const items = useGetFromApi(pathApi);

  const format = x => ({
    id: x.id,
    title: x.name,
    artists: (x.person) ? x.person.owner_name : `DJ UNKNOW`,
    img: (x.image) ? x.image.uri : Config.DEFAULT_IMG,
    audio: null
  });

  return (
    <div style={{ marginTop: 20, ...style}}>
      { (!items)
          ? <Spinner />
          : (
            <div style={{ display: `flex`, flexDirection: `row`, flexWrap: `wrap`, alignContent: `flex-start`, justifyContent: `flex-start` }}>
              { items.map(format).map( (item, i) =>
                <Card key={i}
                  dragEnabled={false}
                  title={item.title}
                  artists={item.artists}
                  img={item.img}
                  audio={{id: item.id}}
                  style={{width: 150, marginRight: 15, marginBottom: 20, position: `relative`}}
                />
              ) }
            </div>
          )
      }
    </div>
  )
}

GridCharts.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  pathApi: PropTypes.string.isRequired,
  style: PropTypes.object
}

export default GridCharts;
