import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

window.djApp = function(params = {}) {
  if (params.div) {
    return ReactDOM.render(<React.StrictMode><App {...params} /></React.StrictMode>, document.getElementById(params.div));
  } else {
    console.log('window.djApp needs the parameter `div` to be able to render the app');
  }
}

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
