import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import CustomDragLayer from './library/CustomDragLayer';
import List from './library/content/List';
import LeftManager from './library/LeftManager';
import TopManager from './library/TopManager';
import Playlist from './library/Playlist';
import CreatePlaylist from './library/CreatePlaylist';
import Search from './library/Search';
import Menu from './library/Menu';
import Content from './library/Content';

import GridTracks from './library/content/GridTracks';
import GridCharts from './library/content/GridCharts';
import Genres from './library/content/Genres';

import { LibraryContext } from '../contexts/library-context';

import useApi from '../hooks/useApi';

const DEFAULT_ACTIVE_MENU = `foryou`;

function Library({ style }) {

  const api = useApi();

  const [playlists, setPlaylists] = useState([]);
  const [valueFilterPlaylist, setValueFilterPlaylist] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [activeID, setActiveID] = useState(DEFAULT_ACTIVE_MENU);
  const [activeNotification, setActiveNotification] = useState(false);
  const [contentComponent, setContentComponent] = useState(null);

  const addPlaylist = (data = []) => {
    setPlaylists([ ...playlists, { id: Date.now(), title: `playlist-${playlists.length}`, tracks: [...data]}]);
  }
  const updatePlaylist = (activeID) => (data) => {
    let playlist = playlists.find(p => p.id === activeID);
    playlist.tracks = data;
    setPlaylists([...playlists]);
  }

  const onClickActiveID = (id) => () => setActiveID(id);
  const onChangeValueFilterPlaylist = (e) => setValueFilterPlaylist(e.target.value) ;
  const filterPlaylists = (x) => valueFilterPlaylist === '' || x.title.includes(valueFilterPlaylist);

  const widthSidePanel = 200;
  const heightTopPanel = 40;
  // const widthTopPanel = window.innerWidth - widthSidePanel;

  return (
    <DndProvider backend={HTML5Backend}>
      <div id="library" style={style} className="library">
        <LibraryContext.Provider value={{selectedItems, setSelectedItems, setContentComponent, api}}>

          <LeftManager title={`Playlists`} styleTitle={{margin: `15px 0px 10px 15px`, color: `white`}} style={{ Zindex: 10, position: `sticky`, top: 0, float: `left`, width: widthSidePanel, height: `100%`, textAlign: `left`, background: `#1f1e1f`}}>
            <CreatePlaylist style={{margin: `15px 15px 15px 15px`, color: `#0ce396`}} onClick={addPlaylist} drop={(source) => {
              addPlaylist(selectedItems);
              setActiveNotification(true);
              setTimeout(() => { setActiveNotification(false) }, 1000);
            }} />
            <Search placeholder="Playlist Name" value={valueFilterPlaylist} onChange={onChangeValueFilterPlaylist}  style={{margin: `15px 15px 15px 15px` }} />
            { playlists.filter(filterPlaylists).map( p => <Playlist key={p.id} name={p.title}
                style={{ fontWeight: (activeID === p.id) ? `bold` : `normal`, margin: `10px 15px 10px 15px`, color: (activeID === p.id) ? `white` : `#8a8a90`, cursor: `pointer` }}
                onClick={() => {
                  setActiveID(p.id);
                  const h = window.innerHeight - 379 - 140; //TODO
                  setContentComponent({
                   id: p.id,
                   type: List,
                   params: {
                     title: p.title,
                     data: p.tracks,
                     sortable: true,
                     idParent: p.id,
                     setParent: updatePlaylist(p.id),
                     width: `120%`,
                     height: h,
                     size: 42
                   }
                 });
                }}
                drop={(source) => {
                  p.tracks.push(...selectedItems);  // p.tracks.push(source)
                  setActiveNotification(true);
                  setTimeout(() => { setActiveNotification(false) }, 1000);
                  if (window.timers[p.title]) { clearTimeout(window.timers[p.title]); window.timers[p.title] = null; }
                } }
              />
            )}
          </LeftManager>

          <TopManager style={{ backgroundColor: `#1f1e1f`, position: `sticky`, top: 0, zIndex: 6, display:`flex`, height: heightTopPanel, paddingLeft: 10 }}>
            <Menu title={`FOR YOU`} active={(activeID === `foryou`)} onClick={onClickActiveID(`foryou`)}>
              <Content name={`FOR YOU`}>
                <GridTracks label={`Recommended`} pathApi={`/catalog/tracks/top/12/?is_available_for_streaming=true&per_page=12`}  />
                <GridTracks label={`New Releases`} pathApi={`/catalog/tracks/top/100/?is_available_for_streaming=true&per_page=12&page=2`} />
              </Content>
            </Menu>
            <Menu title={`GENRES`} active={(activeID === `genres`)} onClick={onClickActiveID(`genres`)}>
              <Content name={`GENRES`}>
                <Genres title={`genres`} />
              </Content>
            </Menu>
            <Menu title={`DJ CHARTS`} active={(activeID === `charts`)} onClick={onClickActiveID(`charts`)} >
              <Content name={`DJ CHARTS`}>
                <GridCharts pathApi={`/catalog/charts/?per_page=30`} />
              </Content>
            </Menu>
            { /* <Menu title={`PURCHASES`} onClick={() => {}} active={(activeID === `purchases`)} /> */ }
            { /*
              <Menu title={`CATALOG`} active={(activeID === `catalog`)} onClick={onClickActiveID(`catalog`)}>
                <Content name={`Catalog`}>
                  <List pathApi={`https://you.dj/server/hotsongs_0.php`}
                      sortable={false} idParent={`catalog`} setParent={updatePlaylist(`catalog`)}
                      width={`120%`} height={270} size={42}
                      style={{marginTop: 20}}
                  />
                </Content>
              </Menu>
            */ }

            <Menu title={`API-${api ? `OK` : `NOK`}`} active={!!(api)} style={{ right: 0, margin: `10px 10px 10px 0px`, position: `absolute`  }}  />
          </TopManager>

          <div style={{backgroundColor: `black`, textAlign: `left`, padding: `20px 20px 5px 220px`}}>
            { contentComponent && React.createElement(contentComponent.type, contentComponent.params) }
          </div>

          <div className={`notification ${activeNotification ? `show` : `hide`}`} onClick={() => setActiveNotification(!activeNotification)} >Added</div>

          <CustomDragLayer />
        </LibraryContext.Provider>
      </div>
    </DndProvider>
  );

// <CustomDragLayer onDragActive={ onSetMenu('playlists') } />
// <List items={items} sortable={isSortable} idParent={activeID} setParent={updatePlaylist(activeID)} width={`88%`} height={500} size={25} />

}

Library.propTypes = {
  style: PropTypes.object
}

export default Library;
