import React from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';

function MidDropBox({ accept, style, drop }) {

  const [{ canDrop, isOver }, refDrop] = useDrop({
    accept,
    drop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })
  const opacity = (canDrop && isOver) ? 1 : 0;

  //TODO - UPGRADE
  // document.body.style.cursor = (canDrop && isOver) ? `copy` : "default";

  return <div ref={refDrop} style={{...style, opacity}} />
}

MidDropBox.propTypes = {
  accept: PropTypes.string.isRequired,
  style: PropTypes.object,
  drop: PropTypes.func.isRequired
}

export default MidDropBox;
