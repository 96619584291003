import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';

import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { LibraryContext } from '../../../contexts/library-context';
import List from './List';

import './Card.css';

function Card({ title, artists, img, style, audio, dragEnabled, onClick }) {
  const { api, setSelectedItems, setContentComponent } = useContext(LibraryContext);

  const play = (_pid) => () => {
    api.getFromApi(`/catalog/tracks/${audio.id}/download/`)
      .then( (response) => {
        console.log('OH YEAH - GET AUDIO FROM API BEATPORT')
        // window.context_unlock();
        // window[`player${_pid}`].loadsong({
        //   type: 1,
        //   a: audio.artists,
        //   bpm: audio.bpm,
        //   d1: audio.length_s,
        //   d2: audio.lenght,
        //   i: audio.img,
        //   t: audio.title,
        //   url: response.data.location
        // }, true);

        window['youdj'].yoann(_pid, response.data.location, audio.title, audio.length_s, audio.id, audio.img, true);

        // window[`player${_pid}`].loadsong(audio, true);
      } )
      .catch( e => console.log(`error`, e.response.status, e.response.statusText) );
  }

  const begin = () => setSelectedItems([audio]);
  const end = (item, monitor) => setSelectedItems([]);

  const [{ opacity }, dragRef, preview] = useDrag({
    item: { ...audio, type: 'TRACK' /*, idParent*/ },
    begin: begin,
    end: end,
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1
    })
  })

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])

  const openLink = () => {
    if (!dragEnabled) {
      const h = window.innerHeight - 379 - 140; //TODO
      setContentComponent({
       id: audio.id,
       type: List,
       params: {
         title: `${title} - ${artists}`,
         pathApi: `/catalog/charts/${audio.id}/tracks/`,
         idParent: `C-${audio.id}`,
         setParent: () => console.log(`TODO DEFINE`),
         sortable: false,
         idParent: audio.id,
         height: h
       }
     })
    }
  }

  return (
    <div ref={(dragEnabled) ? dragRef : null} onClick={openLink} className={`CardBP`} style={{...style, opacity, cursor: (dragEnabled) ? `grab` : `pointer`}}>
      {(audio && dragEnabled) && (
        <div className="oo" style={{backgroundColor: `black`, opacity: 0.7, width: 150, height: 150, position: `absolute`}}>
          <i onClick={play(1)} className="fa fa-play-circle" style={{color: `white`, position: `absolute`, top: `40%`, left: `10%`,  transform: `rotate(180deg)`}} aria-hidden="true"/>
          <i onClick={play(2)} className="fa fa-play-circle" style={{color: `white`, position: `absolute`, top: `40%`, right: `10%`}} aria-hidden="true"/>
        </div>
      )}
      <img src={img} alt={`${title} - ${artists}`} style={{ width: style.width, height: style.width }} />
      <span style={{display: `block`, fontSize: 12, color: `white`}}>{title}</span>
      <span style={{display: `block`, fontSize: 12, color: `#8a8a90`}}>{artists}</span>
    </div>
  )
}

// `rgb(20 204 136)`

Card.propTypes = {
  title: PropTypes.string.isRequired,
  artists: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  audio: PropTypes.object,
  style: PropTypes.object,
  onClick: PropTypes.func,
}

export default Card;
