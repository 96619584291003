import React, { useEffect, useState } from 'react';
import useScript from './hooks/useScript';
import useStyle from './hooks/useStyle';
import contextWorker from './contextWorker';
import './App.css';

import Wave from './components/Wave';
import Master from './components/Master';
import Deck from './components/Deck';
import Mixer from './components/Mixer';
import Library from './components/Library';
import Settings from './components/Settings';

// import { Stage, ParticleContainer } from "react-pixi-fiber";
// import ParticleContainerManager from "./components/library/ParticleContainerManager";

const height = 100;
const width = 900;
const OPTIONS = {
  backgroundColor: 0x1099bb,
  height: height,
  width: width,
  antialias: true
};

// require('core/_jquery');

function App() {

// *** NO IFRAME
  // const dependencies = [
  //   useStyle('core/_style.css'),
  //   useScript('core/_main.js'),
  //   useScript('core/_player.js'),
  //   useScript('core/_music.js'),
  //   useScript('core/_midi.js'),
  //   useScript('core/_core_main.js'),
  //   useScript('core/_core_player.js'),
  //   useScript('core/_core_audio.js'),
  //   useScript('core/_core_audio_eq.js'),
  //   useScript('core/_core_audio_sfx.js'),
  //   useScript('core/_core_audio_stretch.js')
  // ]
  // const loaded = dependencies.every( x => x === true );
  // const iframe = false;
  //
  // useEffect(() => {
  //   console.log('after render', loaded);
  //   loaded && new contextWorker();
  // }, [loaded]);
// *** IFRAME
  const iframe = true;
  const loaded = true;


//TODO - TEST - WEBGL
  // const waves = new Array(500).fill(`x`);

  // <Stage options={OPTIONS}>
  //   <ParticleContainer>
  //     <ParticleContainerManager items={waves} />
  //
  //   </ParticleContainer>
  // </Stage>


  return (loaded) ? (
    <div className="App">
    {/*
      <div id="big_bg" className="big_bg" style={{display: `none`}}/>

      <Wave id={`wave_canvas`} enable={`true`} width={1278} height={60} top={0} />
      <Wave id={`wave_canvas2`} enable={`true`} width={1278} height={60} top={60} />

      <div id="cursor1" style={{display: `none`, position: `absolute`, zIndex: 9999, cursor: `pointer`, width: `100%`, height: 278 }} />
      <div id="cursor2" style={{display: `none`, position: `absolute`, zIndex: 9999, cursor: `grab`, width: `100%`, height: 278}} />

      <div id="player" style={{position: `relative`, top: 12, width: 1278, height: 278, margin: `auto`}}>
        <Master>
          <Deck inverted={false} style={{position: `absolute`, top: 0, left: 0, width: 468, height: 278}} />
          <Mixer style={{position: `absolute`, top: 0, left: 475, width: 320, height: 278}} />
          <Deck inverted={true} style={{position: `absolute`, top:0, left:813, width:468, height:278 }} />
        </Master>

        <div style={{display: `none`}}>
          <div id="trash" />
        </div>
      </div>
      */}

      <Library style={{
        // top: (iframe) ? 0 : 20,
        // position: `relative`,
        overflowY: `scroll`, overflowX: `hidden`,
        background: `#1f1e1f`,

        backgroundColor: `black`,
        position: `absolute`,
        width: `100%`,
        height: `calc(100% - 379px)`,
        marginTop: -11
      }} />

{/*
      <Settings />

      <div id="bigmsg" className="bigmsg">
        <div id="bigmsg0" className="bigmsg_close" onMouseUp={() => window.showBigDiv(null)}>&#10005;</div>
        <div id="bigmsg1" className="bigmsg_title" />
        <div id="bigmsg2" className="bigmsg_content" style={{fontSize:16}} />
      </div>

      <div id="login" style={{width: 815, height: 360}} className="bigmsg">
      	<div className="bigmsg_close" onMouseUp={() => window.showBigDiv(null)}>&#10005;</div>
      	<div className="bigmsg_title">Login</div>
      	<table height="225" style={{marginLeft: 26}}>
          <tbody>
        		<tr>
              <td>
                <form>
            			<div className="logininput"><input id="login3_email" placeholder="Your email" type="email"/>
                    <i style={{position: `absolute`, top: 8, left:16, fontSize: 18}} className="fas fa-at"></i></div>
            			<div className="logininput"><input id="login3_pass" placeholder="Your password" type="password"/>
                    <i style={{position: `absolute`, top: 8, left:16, fontSize: 17}} className="fas fa-key"></i>
                  </div>
            			<a onMouseUp={ () => window.login3_login()} href="#login" className="loginbut loginbut3">
                    <i style={{position: `relative`, top:3, fontSize:23, marginRight: 19}} className="far fa-paper-plane"></i>Login with email&nbsp;&nbsp;<img id="login3_loading" className="login3_loading" src="picx/loading.gif" /></a>
            			<div className="loginerror" id="login3_error"></div>
        		    </form>
              </td>
        	  </tr>
          </tbody>
        </table>
      </div>
      */}

    </div>
  ) : null;
}

export default App;
