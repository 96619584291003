import * as Config from '../../../config';
import React , { useState } from 'react';
import PropTypes from 'prop-types';

import Content from '../Content';
import GridTracks from './GridTracks';
import List from './List';

function Genre({ id, name, style }) {
  const [menu, setMenu] = useState(`featured`);
  const selectMenu = (name) => () => setMenu(name);

  const h = window.innerHeight - 379 - 140; //TODO
  // `250`

  return (
    <Content name={name}>
      <div style={{ marginBottom: -15, display: `flex`, flexDirection: `row`, flexWrap: `wrap`, alignContent: `flex-start`, justifyContent: `flex-start`}}>
        <h5 onClick={selectMenu(`featured`)} style={{marginRight: 20, fontWeight: (menu === `featured`) ? `bold` : `normal`, cursor: `pointer`, color: (menu === `featured`) ? Config.BCOLOR_GREEN : `#8a8a90` }}>FEATURED</h5>
        <h5 onClick={selectMenu(`top100`)} style={{marginRight: 20, fontWeight: (menu === `top100`) ? `bold` : `normal`, cursor: `pointer`, color: (menu === `top100`) ? Config.BCOLOR_GREEN : `#8a8a90` }}>TOP 100</h5>
      </div>
      { (menu === `featured`) && <GridTracks label={`New Releases`} pathApi={`/catalog/tracks/?per_page=12&genre_id=${id}`} /> }
      { (menu === `top100`) && <List
          pathApi={`/catalog/tracks/?per_page=100&genre_id=${id}`}
          sortable={false}
          idParent={`G-${id}-top100`}
          setParent={() => console.log(`TODO DEFINE`)}
          width={`120%`} height={h} size={42}
          style={{marginTop: 10}}
      /> }
    </Content>
  )
}


//250

// <List items={items} sortable={isSortable} idParent={activeID} setParent={updatePlaylist(activeID)} width={`88%`} height={500} size={25} />

// { (menu === `top100`) && <GridTracks label={`Top 100`} pathApi={`/catalog/tracks/?per_page=100&genre_id=${id}`} /> }

// top/100/ ??? TODO ONLY FEW

// <div style={{...style}}>
//   <span style={{ fontSize: `1.5em`, fontWeight: `bold`}}>{name}</span>
//   <div style={{ marginBottom: -15, display: `flex`, flexDirection: `row`, flexWrap: `wrap`, alignContent: `flex-start`, justifyContent: `flex-start`}}>
//     <h5 onClick={selectMenu(`featured`)} style={{marginRight: 20, fontWeight: (menu === `featured`) ? `bold` : `normal`, color: (menu === `featured`) ? Config.BCOLOR_GREEN : `#8a8a90` }}>FEATURED</h5>
//     <h5 onClick={selectMenu(`top100`)} style={{marginRight: 20, fontWeight: (menu === `top100`) ? `bold` : `normal`, color: (menu === `top100`) ? Config.BCOLOR_GREEN : `#8a8a90` }}>TOP 100</h5>
//   </div>
//   { (menu === `featured`) && <GridTracks label={`New Releases`} pathApi={`/catalog/tracks/?per_page=12&genre_id=${id}`} /> }
//   { (menu === `top100`) && <GridTracks label={`Top 100`} pathApi={`/catalog/tracks/top/100/?per_page=100&genre_id=${id}`} /> }
// </div>

Genre.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  style: PropTypes.object,
}

export default Genre;
