import React from 'react';
import PropTypes from 'prop-types';

import GCard from './GCard';
import Spinner from './Spinner';

import useGetFromApi from '../../../hooks/useGetFromApi';

function Genres({ title, style }) {
  const items = useGetFromApi(`/catalog/genres/`);

  return (
    <div style={{...style}}>
      { (!items)
          ? <Spinner style={{marginTop: 20}} />
          : (
            <div style={{ marginTop: 20, display: `flex`, flexDirection: `row`, flexWrap: `wrap`, alignContent: `flex-start`, justifyContent: `flex-start` }}>
              { items.map( (item,i) => <GCard key={i} id={item.id} name={item.name}/> ) }
            </div>
          )
      }
    </div>
  )
}

Genres.propTypes = {
  title: PropTypes.string.isRequired,
  style: PropTypes.object
}

export default Genres;
