import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Genre from './Genre';
import { LibraryContext } from '../../../contexts/library-context';

function GCard({ id, name, style }) {
  const { setContentComponent } = useContext(LibraryContext);

  const listGenre = () => {
    setContentComponent({
       type: Genre,
       params: { name, id }
     })
   };

// backgroundImage: `linear-gradient(to right, rgb(38 15 230), rgb(121 122 167))`,
  return (
    <div onClick={listGenre} style={{
      position: `relative`,
      marginRight: 20, marginBottom: 20,
      borderRadius: 10, width: 300, height: 100,
      backgroundColor: `#3700b3`,
      cursor: `pointer`,
      ...style}
    }>
      <h3 style={{position: `absolute`, bottom: 0, left: 20, color: `white`}}>{name}</h3>
    </div>
  )
}

GCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  style: PropTypes.object
}

export default GCard;
